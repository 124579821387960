<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:10px">Bursary Dashboard </span>
        </div>
    </div>
        <div style="margin-top:165px;">
<div class="row">
    <div class="col-md-12">

        
        <b-row>
            <div class="col-md-4" style="padding:3px !important">
                <div class="card border-0 shadow-sm" style="height:150px; padding:1px !important">
                    <v-row
        class=""
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Calculating Portal Transactions for today
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
            striped
          ></v-progress-linear>
        </v-col>
      </v-row>
                    <div class="" style="padding:5px 5px 5px 5px">
                        <div class="fee-analysis" style="padding:5px 0 0px 0">
                        Portal Transactions Today {{incomeTotals.today}}<br>

                        </div>
                        
                        <div style="height:40px; margin-bottom:15px">
    <div class="moneynomargin">
Total : &#8358;{{incomeTotals.todayAmount.toLocaleString()}}

    </div>
    <div>
Count: {{incomeTotals.todayCount}}
    </div>

</div>
<div>
    <!-- {{marqDay}} -->
                        <marquee attribute_name = "attribute_value" scrollamount="2" onmouseover="this.stop();" onmouseout="this.start();" class="marq">
<span v-for="(detail, index) in marqDay" :key="index">{{detail.name}} <v-icon style="color:#EF8288">mdi-arrow-right-bold</v-icon> Amount : &#8358;{{detail.amount.toLocaleString()}} - Count : {{detail.count}} ..... </span>
</marquee></div>
                        </div>
                        
                    </div>

                    <div class="card border-0 shadow-sm" style="height:150px; padding:1px !important; margin-top:5px; margin-bottom:5px">
                        <v-row
        class=""
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Calculating Portal Transactions for the month
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
            striped
          ></v-progress-linear>
        </v-col>
      </v-row>
                        <div class="" style="padding:5px 5px 5px 5px">
                        <div class="fee-analysis" style="padding:5px 0 0px 0">
                            Portal Transactions This Month<br>
                            <!-- <img class="float-right group205" src="@/assets/Group205.svg" alt="myunizik"> -->
                        </div>

                        <div style="height:40px; margin-bottom:15px">
                            <div class="moneynomargin" sty>
                            Total : &#8358;{{incomeTotals.monthAmount.toLocaleString()}}
                            </div>
                            <div>
Count: {{incomeTotals.monthCount}}</div>
</div>
<marquee attribute_name = "attribute_value" scrollamount="2" onmouseover="this.stop();" onmouseout="this.start();" class="marq">
<span v-for="(detail, index) in marqMonth" :key="index">{{detail.name}} <v-icon style="color:#EF8288">mdi-arrow-right-bold</v-icon> Amount : &#8358;{{detail.amount.toLocaleString()}} - Count : {{detail.count}} ..... </span>
</marquee>
              
                        </div>
                        
                    </div>
                    <div class="card border-0 shadow-sm" style="height:150px; padding:1px !important">
                        <v-row
        class=""
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Calculating Portal Transactions for The Year
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
            striped
          ></v-progress-linear>
        </v-col>
      </v-row>
                    <div class="" style="padding:5px 5px 5px 5px">
                        <div class="fee-analysis" style="padding:5px 0 0px 0">
                            Portal Transactions This Year<br>
                            <!-- <img class="float-right group205" src="@/assets/Group205.svg" alt="myunizik"> -->
                        </div>

                        <div style="height:40px; margin-bottom:15px">
                            <div class="moneynomargin">
                            Total : &#8358;{{incomeTotals.yearAmount.toLocaleString()}}
                            </div>
                            <div>
Count : {{incomeTotals.yearCount}}</div>
</div>
<marquee attribute_name = "attribute_value" scrollamount="2" onmouseover="this.stop();" onmouseout="this.start();" class="marq">
<span v-for="(detail, index) in marqYear" :key="index">{{detail.name}} <v-icon style="color:#EF8288">mdi-arrow-right-bold</v-icon> Amount : &#8358;{{detail.amount.toLocaleString()}} - Count : {{detail.count}} ..... </span>
</marquee>
              
                        </div>
                        
                    </div>

                </div>

                <div class="col-md-4" style="padding:3px !important">

<div class="card border-0 shadow-sm" style="height:auto; padding-right:1px !important">
    <div class="" style="padding:5px 5px 5px 5px">
        <div class="fee-analysis" style="padding:5px 0 15px 0">
            2021 / 2022 Academic Session<br>Fee Analysis
            <img class="float-right group205" src="@/assets/Group 192.svg" alt="myunizik">
        </div>

        <v-carousel hide-delimiters show-arrows-on-hover cycle height="270">
            <v-carousel-item v-for="(fee, index) in chuka" :key="index">
                <div style="font-size:18px; font-weight:; color:#666">
                                     <strong>{{fee.name}}</strong>
                                </div>
                                <v-divider style="margin:0; margin-bottom:10px"></v-divider>

                <h4 class="money">&#8358; {{fee.paidfeesAmount.toLocaleString()}}</h4>
                <div class="row">
                    <div class="row justify-content-left">
                                    <p class="col-md-12 paid ">
                                        No. of Students that have Paid {{fee.name}}<br>{{fee.paidfeesCount}}
                                    </p>
                                    <p class="col-md-12 paid2">
                                        No. of Students that have Not Paid {{fee.name}}<br> {{fee.unpaidCount}}
                                    </p>
                                    <p class="col-md-12 paid2">
                                        No. of Students that have paid part of {{fee.name}}<br> {{fee.paidPartfeesCount}}
                                    </p>
                                </div>
                    <div class="col-md-8" style="padding:0">
                      <br>
                        <div>
                            <!-- <div style="font-size:12px; color:#008ffa; font-weight:bold">
                                Expected
                            </div> -->
                            <h4 class="money"><span style="font-size:12px; color:#008ffa; font-weight:bold">Expected :</span> &#8358;{{fee.amountExpected.toLocaleString()}}</h4>
                        </div>
                        <div>
                            <!-- <div style="font-size:12px;font-weight:bold; color:green">
                                Recieved
                            </div> -->
                            <h4 class="money"><span style="font-size:12px;font-weight:bold; color:green">Recieved:</span> &#8358;{{fee.paidfeesAmount.toLocaleString()}}</h4>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="" style="margin-top:-30px">
                            <Sidepie :series="[fee.expectedAmount,fee.totalAmountPaid]" />
                            <div class="row" style="margin-top:-20px !important">
                                <div class="col-md-12" style="margin:1px; padding:0px; font-size:10px">
                                     <span class="dot" style="background:#008ffa"></span>
                                    Expected</div>
                                <div class="col-md-6" style="margin:1px; padding:0px ; font-size:10px"> <span class="dot" style="background:#00e496"></span>
                                    Recieved</div>
                            </div>

                        </div>
                    </div>

                </div>
            </v-carousel-item>
        </v-carousel>
    </div>
</div>
</div>
            <div class="col-md-4" style="padding:2px !important">

                <div class="card border-0 shadow-sm" style="height:auto; padding:1px !important">
                    <div class="" style="padding:5px 5px 5px 5px">
                        <div class="fee-analysis" style="padding:5px 0 15px 0">
                            2021 Academic Session<br>Fee Analysis
                            <img class="float-right group205" src="@/assets/Group205.svg" alt="myunizik">
                        </div>
                        <v-carousel hide-delimiters show-arrows-on-hover cycle height="270">
                            <v-carousel-item v-for="(fee, index) in chuka" :key="index">

                                <div style="font-size:18px; font-weight:; color:#666">
                                     <strong>{{fee.name}}</strong>
                                </div>
                                <v-divider style="margin:0; margin-bottom:10px"></v-divider>


                                <div class="col-md-12 " style="margin:1px; padding:0px; font-size:10px;background-color:#d7ebf5; border-radius: 4px;">

                                <h4 class="money"><span style="font-size:13px">Expecting:</span><br> > &#8358;{{fee.amountExpected.toLocaleString()}}</h4>
</div>
<div class="col-md-12 " style="margin:1px; padding:0px; font-size:10px; background-color:#94EFB5; border-radius: 4px;">

                                <h4 class="money"><span style="font-size:13px">Fully Paid:</span> <br>
                                    &#8358;{{fee.paidfeesAmount.toLocaleString()}}</h4>
                                </div>

                                <div class="col-md-12 " style="margin:1px; padding:0px; font-size:10px; background-color: #F8C4BC; border-radius: 4px;">
<h4 class="money"><span style="font-size:13px">Paid Part:</span> <br>
    &#8358;{{fee.paidPartfeesAmount.toLocaleString()}}</h4>
</div>
                                <!-- <div id="chart" style="margin:auto !important" class="col-md-12">
        <apexchart type="donut" width="300" :options="chartOptions1" :series="[fee.amountExpected,fee.paidfeesAmount]" style="margin-left:10%" ></apexchart>
      </div> -->
                            </v-carousel-item>
                        </v-carousel>
                    </div>
                </div>
            </div>
           
        </b-row>
        <b-row class="mt-2">
            <div class="col-md-8" style="padding:1px !important">
                <div class="card shadow-sm">
                    <div id="chart">
                        <div class="analysis px-5 py-2">2021 / 2022 Academic Session Payment Trend</div>
                        <div class="analysis2 px-5 py-2">Fee Payment Monthly Trend</div>
                        <apexchart type="area" height="300" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </div>
            </div>

            <div class="col-md-4" style="padding:2px !important">

<div class="card border-0 shadow-sm" style="height:auto; padding:1px !important">
    <div class="" style="padding:5px 5px 5px 5px">
        <div class="fee-analysis" style="padding:5px 0 15px 0">
            2021 Academic Session<br>Fee Analysis
            <img class="float-right group205" src="@/assets/Group205.svg" alt="myunizik">
        </div>
        <v-carousel hide-delimiters show-arrows-on-hover cycle height="270">
            <v-carousel-item v-for="(fee, index) in chuka" :key="index">

                <div style="font-size:18px; font-weight:; color:#666">
                     <strong>{{fee.name}}</strong>
                </div>
                <v-divider style="margin:0; margin-bottom:10px"></v-divider>


                <!-- <div class="col-md-12 " style="margin:1px; padding:0px; font-size:10px;background-color:#d7ebf5; border-radius: 4px;">

                <h4 class="money"><span style="font-size:13px">Expecting:</span><br> > &#8358;{{fee.amountExpected.toLocaleString()}}</h4>
</div>
<div class="col-md-12 " style="margin:1px; padding:0px; font-size:10px; background-color:#94EFB5; border-radius: 4px;">

                <h4 class="money"><span style="font-size:13px">Fully Paid:</span> <br>
                    &#8358;{{fee.paidfeesAmount.toLocaleString()}}</h4>
                </div>

                <div class="col-md-12 " style="margin:1px; padding:0px; font-size:10px; background-color: #F8C4BC; border-radius: 4px;">
<h4 class="money"><span style="font-size:13px">Paid Part:</span> <br>
&#8358;{{fee.paidPartfeesAmount.toLocaleString()}}</h4>
</div> -->
                <div id="chart" style="margin:auto !important" class="col-md-12">
<apexchart type="donut" width="300" :options="chartOptions1" :series="[fee.amountExpected,fee.paidfeesAmount]" style="margin-left:10%" ></apexchart>
</div>
            </v-carousel-item>
        </v-carousel>
    </div>
</div>
</div>
        </b-row>
    </div>
    
</div>
</div>
</div>

</template>

<script>
import Sidepie from "./Sidepie.vue"
import io from 'socket.io-client';
import axios from 'axios';
export default {
    data: function () {
        return {
            socket : io('localhost:3002'),
            slide: 0,
            donutSeries: [],
            dataLoaded:false,
            // programSearch : this.$router.currentRoute.params.activeSetting.data.data.program._id,
            sliding: null,
            feesArr: [],
            portalIncome: null,
            expectedAmount: null,
            departmentAnalysis: [],
            facultyAnalysis: [],
            incomeTotals:'',
            marqDay:[],
            marqMonth:[],
            marqYear:[],
            series: [],
            chuka: [],



            series1: [],
          chartOptions1: {
            chart: {
              width: 280,
              type: 'donut',
            },
            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 270
              }
            },
            colors: ['#e09294', '#47da4d'],
            labels: ['Not Paid', 'Paid'],
            dataLabels: {
              enabled: true
            },
            fill: {
              type: 'gradient',
            },
            
            legend: {
            //   formatter: function(val, opts) {
            //     console.log(opts.w.globals);
            //     return 'Expected' + " - " + opts.w.globals.label[opts.dataPointIndex].toLocaleString()
            //   },
              position: 'bottom',
            },
            title: {
            //   text: 'Gradient Donut with custom Start-angle'
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
          


          series: [],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Product Trends by Month',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: [],
            }
          },




        //   series:[]
        //     chartOptions: {
        //         dropShadow: {
        //             enabled: false,

        //         },
        //         chart: {
        //             height: 300,
        //             type: 'area'
        //         },
        //         dataLabels: {
        //             enabled: false
        //         },
        //         stroke: {
        //             curve: 'smooth'
        //         },
        //         xaxis: {
        //             type: 'datetime',
        //             categories: ['2021-11-10', '2021-11-17', '2021-11-15'],
        //             tickAmount: 4,
        //             labels: {
        //                 formatter: function (value, timestamp, opts) {
        //                     return opts.dateFormatter(new Date(timestamp), 'dd MMM')
        //                 }
        //             }
        //         },
        //     },
        }

    },
    mounted() {
        this.fetchFeeAnalysis();
        this.fetchfeeIncome();
        // this.socket.on('MESSAGE', (data) => {
        //     this.messages = [...this.messages, data];
        //     // you can also do this.messages.push(data)
        // });
    },
    created(){
    // this.intervalAnalysis = setInterval(() =>{
    //   this.fetchFeeAnalysis()},300000)

    //   this.intervalIncome = setInterval(() =>{
    //   this.fetchfeeIncome()},300000)
  },
  destroyed(){
    clearInterval(this.intervalAnalysis)
    clearInterval(this.intervalIncome)
  },

  created() {
            window.onbeforeunload = () => {
                socket.emit('leave', this.username);
            }
            
            socket.on('chat-message', (data) => {
                this.messages.push({
                    message: data.message,
                    type: 1,
                    user: data.user,
                });
            });

            socket.on('typing', (data) => {
                this.typing = data;
            });


            socket.on('stopTyping', () => {
                this.typing = false;
            });

            socket.on('joined', (data) => {
                this.info.push({
                    username: data,
                    type: 'joined'
                });

                setTimeout(() => {
                    this.info = [];
                }, 5000);
            });

            socket.on('leave', (data) => {
                this.info.push({
                    username: data,
                    type: 'left'
                });

                setTimeout(() => {
                    this.info = [];
                }, 5000);
            });

            socket.on('connections', (data) => {
                this.connections = data;
            });
        },

        watch: {
            newMessage(value) {
                value ? socket.emit('typing', this.username) : socket.emit('stopTyping')
            }
        },





    methods: {
      
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        async fetchFeeAnalysis(x) {
          console.log(x);
            try {
                const url = this.$appUrl+'/get-fee-analysis'
                const res = await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        session: 2021,
                        program:'61ef4514a88631b45cd21f31'
                    }
                })
                console.log(res.data.code);
                if (res.data.code == '00') {
                    const {
                        message
                    } = res.data;
                    console.log(message);
                    this.chuka = message;
                    // this.portalIncome = data.totalAmountPaid;
                    // this.facultyAnalysis = this.feesArr.faculty;
                    // this.donutSeries = [data.expectedAmount, data.totalAmountPaid];
                    //this.departmentAnalysis = this.feesArr.departments;
                }
            } catch (err) {
                console.log(err)
            }
        },

        async fetchfeeIncome() {
            try {
                this.series=[];
                const url = this.$appUrl+'/get-fee-income'
                const res = await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        session: 2021,
                        program:'61ef4514a88631b45cd21f31',
                        month:new Date()
                    }
                })
                if (res.data.code === '00') {
                    
                    this.incomeTotals = res.data.message.total;
                    this.marqDay =res.data.message.today
                    this.marqMonth =res.data.message.month
                    this.marqYear = res.data.message.year


                    this.chartOptions.xaxis.categories=[];
///graph 
for (let index = 1; index <= res.data.message.graphday; index++) {
    this.chartOptions.xaxis.categories.push(index)
   }

this.marqMonth.forEach((element,key) => {
    const buildSeries = new Object();
    buildSeries.name =element.name;
    buildSeries.data =[]
   for (let index = 1; index <= res.data.message.graphday; index++) {
    // const element = array[index];
    const found = element.data.filter(element => element.trans_date.split('/')[1] == index);
    console.log('found',found);
if(found.length > 0){
   const totalFound = found.reduce((total, obj) => parseFloat(obj.trans_amount) + parseFloat(total),0)
    buildSeries.data.push(totalFound) 
}else{
    buildSeries.data.push('0') 
}
   }
    this.series.push(buildSeries)
    //console.log(key);
});

                    // console.log(responseDatas)
                    // for (const val of Object.values(responseDatas)) {
                    //     datas.push(val);
                    // }
                    // for (const el of datas) {
                    //     const amount = [];
                    //     for (const val of el.data) {
                    //         const date = val._id.split('T');
                    //         dates.push(date[0]);
                    //         amount.push(val.amountPaid);
                    //     }
                    //     this.series.push({
                    //         name: el.fee,
                    //         data: amount
                    //     })
                    // }
                    // console.log(dates);
                    // this.chartOptions.xaxis.categories = dates;
                    // console.log(this.series)



//                     for (var x = 1; x < 17; x++) {
//     const datttt = reducedObjArr.filter(function(y){
//         if(y.key.split('-')[2] == x){
//                         return y.value
//         }else{
//             return 0
//         }
//                     })
   
//     if(datttt[0] == undefined){
//         objects.push(0)

//     }else{ objects.push(datttt[0].value)}
   
   

// }
this.dataLoaded = true
                }
            } catch (err) {
                console.log(err)
            }
        }
    },
    components: {
       Sidepie,
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

.dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.money {

    color: #555;
    font-size: 20px;
    font-weight: 600;
    color:#666 !important;
    padding:5px
}
.moneynomargin{
    color: #555;
    font-size: 20px;
    font-weight: 600;
    color:#666 !important;
}
.marq{
    background-color:#666; color:rgb(47, 255, 64); font-size:14px;font-weight:bold
}

.money1 {
    font-size: 20px;
}

.row {
    margin: 0 !important;
    padding: 0 !important;
}

.title4 {
    font-size: 1.65rem !important;
    margin-bottom: 5px;
    font-weight: bold;
    color: #4F4F4F;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;

}

.analysis {
    color: #2D3C4F;
    font-family: Proxima Nova;
    font-weight: bold;
    font-size: 15px;

}

.analysis2 {
    color: #2D3C4F;
    font-family: Proxima Nova;
    font-size: 12px;

}

.fee-analysis {
    font-weight: 600;
    color: #4F4F4F;
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 14px;
}

.figure {
    font-weight: bolder;
    color: #4F4F4F;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: normal;
    font-size: 1.60rem;
    margin-top: 10px;
    padding: 0;
}

.number_txt {
    color: #666;
    font-size: 18px;
    font-weight: bold;
    font-family: Proxima Nova;
}

.paid {
    font-size: 14px;
    font-weight: 600;
    background-color: #D7ECF5;
    font-family: Proxima Nova;
    color: #0E6F9A;
    padding: 3px 0 0 5px !important;
}

.paid2 {
    font-size: 14px;
    font-weight: 600;
    background-color: #FAEEEE;
    font-family: Proxima Nova;
    color: #DB8385;
    padding: 3px 0 0 5px !important;

}

.sidebar_txt {
    background: #D7ECF5;
    color: #0E6F9A;
    font-family: Proxima Nova;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
}

.sidebar_txt2 {
    background: #FCE6D3;
    color: #F79F4C;
    font-family: Proxima Nova;
    font-weight: bold;
    font-size: 14px;
    padding: 10px;
}

.paid3 {
    font-size: 12px;
    /* font-weight: bold; */
    width: 35%;
    margin-top: -50px;

}

.paid4 {
    font-size: 12px;
    /* font-weight: bold; */
    width: 35%;
    margin-top: -50px;

}

.group205 {
    margin-top: -35px;
}

.label {
    margin-top: -130px;
}

.bars {
    margin-right: -180px;
}

.row1 {
    margin-top: -60px;
}
</style>
