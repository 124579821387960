<template>
<div id="card">
    <div id="chart">
        <apexchart type="donut" width="170" :options="chartOptions" :series="series"></apexchart>
    </div>
</div>
</template>

<script>
export default {
    props: ['series'],
    data: function () {
        return {
            chartOptions: {
                chart: {
                    width: 380,
                    background: 'transparent',
                    type: 'donut',
                    dropShadow: {
                        enabled: false,
                        color: '#111',
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35
                    }
                },
                stroke: {
                    width: 0,
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        endAngle: 360,
                        expandOnClick: true,
                        offsetX: 0,
                        offsetY: 0,
                        customScale: 1,
                        dataLabels: {
                            offset: 0,
                            minAngleToShowLabel: 10
                        },
                        donut: {
                            size: '50%',
                            background: 'none',
                            labels: {
                                show: false,
                                name: {
                                    show: false,
                                    fontSize: '2px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: -10,
                                    formatter: function (val) {
                                        return val + "%"
                                    }
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: 5,
                                    formatter: function (val) {
                                        return val + '%'
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Total',
                                    fontSize: '15px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 600,
                                    color: 'red',
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b 
                                        }, 0)
                                    }
                                }
                            }
                        },
                    }
                },
                labels: ["Expected Amount", "Amount collected"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    //type: 'gradient',
                    // gradient: {
                    //     // shade: 'dark',
                    //     type: 'horizontal',
                    //     shadeIntensity: 0.1,
                    //     // gradientToColors: ['#A6D997', '#E91E63'],
                    //     inverseColors: true,
                    //     opacityFrom: 1,
                    //     opacityTo: 1,
                    //     stops: [0, 100],
                    // }
                },
                states: {
                    hover: {
                        filter: 'none'
                    }
                },
                theme: {
                    palette: 'palette1'
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            show:false
                        },
                    }
                }],

                legend: {
                   show:false
                }
            },

        }
    },
}
</script>

<style>

</style>
