<template>
<div class="home">
  <v-dialog v-model="dialogLoad" width="200">
    <v-card>
      <v-progress-linear color="#006794" indeterminate rounded height="6"></v-progress-linear>
    </v-card>
  </v-dialog>
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">Invoice</span>/<span style="padding:5px; color:#555">List Paid Invoices</span>
    </div>
  </div>

  <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid"></div>
  <v-row style="margin:0 !important; padding:0 !important">
    <v-card class="pt-8">
      <v-col cols="12" style="">

        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <v-text-field :clearable="clearable" v-model="filter.invoiceRef" class="mt-3" label="Enter Invoice ref" outlined dense></v-text-field>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <v-text-field :clearable="clearable" v-model="filter.regNumber" class="mt-3" label="Enter Reg Number" outlined dense></v-text-field>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <v-autocomplete :clearable="clearable" class="mt-3" :items="faculties" v-model="filter.facultyId" label="Select Faculty" outlined dense></v-autocomplete>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <v-autocomplete :disabled="departmentDisabled" :clearable="clearable" :items="departments" v-model="filter.departmentId" class="mt-3" label="Select Department" outlined dense></v-autocomplete>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <!-- <v-select :clearable="clearable" ></v-select> -->
                <v-autocomplete auto-select-first clearable class="mt-3" :items="fees" v-model="filter.feeId" label="Select Fee" outlined dense></v-autocomplete>
              </div>

              <!-- <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <v-autocomplete auto-select-first clearable class="mt-3" :items="statuses" v-model="filter.status" label="Invoice Status" outlined dense></v-autocomplete>
              </div> -->

              <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <v-dialog
        ref="dialogl"
        v-model="modal"
        :return-value.sync="filter.dateFrom"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-text-field
          outlined
          dense
          class="mt-3" 
            v-model="filter.dateFrom"
            label="Date From"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
          </div>
        </template>

        <v-date-picker
          v-model="filter.dateFrom"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialogl.save(filter.dateFrom)"
          >
            OK
          </v-btn>
        </v-date-picker>

      </v-dialog>

              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 py-0">
                <v-dialog
        ref="dialog"
        v-model="modall"
        :return-value.sync="filter.dateTo"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
         <div>
          <v-text-field
          outlined 
          dense
            v-model="filter.dateTo"
            label="Date To"
            class="mt-3" 
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
         </div>
        </template>
        <v-date-picker
          v-model="filter.dateTo"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="closeDate1"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(filter.dateTo)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

              </div>
<br>
              <div  class="col-12 py-0">

                <div>
                  <v-btn @click="clear" class="mr-1 btn-clear">clear</v-btn>

                  <v-btn @click="filterInvoice" class="btn-search" color="#006794" v-if="!activities">Search</v-btn>
                  <v-btn color="#c0e7fe" @click="save"  class="col-md-5" style="margin:auto" disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Searching</v-btn>
                </div>

              </div>

            </div>
          </div>
          <div class="col-md-8 "> </div>
          <div class="col-md-4 mb-8">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search For Invoice" single-line hide-details></v-text-field>
          </div>
        </div>
        <v-spacer></v-spacer>
                        
                                <button @click="csvExport(csvData3)" class="" type="submit" style="color:#999"> <span style="margin-right: 4px"></span> <v-icon color="#999" large>mdi-download-circle</v-icon><span style="font-size:12px">Download</span></button>

        <v-data-table :search="search" :headers="invoiceHeaders" :items="invoiceDatas">

          <!-- <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{invoiceDatas.map(function(x) {return x.regNumber; }).indexOf(item.regNumber)+1}}</td>
                        </tr>
                    </template> -->

          <!-- <template v-slot:item.actions="{ item }">
            <v-icon @click="viewInvoice(item)">mdi-eye</v-icon>

          </template> -->


          <template v-slot:item.settlement="{ item }">
                                  <div v-if="!item.settlement">
                                    Manual
                                    <!-- <span class="dot-red"></span> -->
                                    </div>
                                    <div v-if="item.settlement == 1">
Automatic
                                      <!-- <span class="dot-green"></span> -->
                                    </div>
                                    <div v-if="item.settlement == 2">
                                      <!-- <span class="dot-amber"></span> -->
Manual
                                    </div>
                                </template>
                                
        </v-data-table>
        <div class="pa-3 text-right">
          <v-btn :disabled="pageCount - 1 == 1" @click="prev" class="mr-3">Prev</v-btn>
          <v-btn @click="next">Next</v-btn>
        </div>
      </v-col>
    </v-card>
  </v-row>

  
</div>
</template>

<script>
import {allFaculties,allSession,allDepartments} from '@Appmart/utility'

import axios from 'axios';
export default {
  data() {
    return {
      activities:false,
      search: '',
      dialogLoad: false,
      departmentDisabled: true,
      pageCount: 2,
      modal: false,
      modall: false,
      dateFrom: '',
      dateTo: '',
      departments: [],
      clearable: true,
      filter: {
        departmentId: '',
        facultyId: '',
        feeId: '',
        dateFrom: '',
        dateTo: '',
        regNumber: '',
        invoiceRef: '',
        programId: '',
        
      },
      fees: [],
      statuses:[{
      text:'All',
      value:''
      },{
      text:'Not Paid',
      value:0
      },{
      text:'Part Payment',
      value:2
      },{
      text:'Paid Full',
      value:1
      }],
      invoiceHeaders: [
        {
          text: 'S/N',
        },
        {
          text: 'Fee Name',
          value: 'feename'
        },
        {
          text: 'Reg No.',
          value: 'regnumber'
        },
        {
          text: 'Name',
          value: 'stuname'
        },
       
        {
          text: 'Amount To Pay',
          value: 'amountToPay'
        },
        {
          text: 'Amount Paid',
          value: 'amountPaid'
        },
        {
          text: 'Settlement',
          value: 'settlement'
        },
       
        {
          text: 'Session Paid',
          value: 'session'
        },
        {
          text: 'Faculty',
          value: 'faculty'
        },
        {
          text: 'Department',
          value: 'department',
        },
        {
          text: 'Generated',
          value: 'genDate'
        },
        {
          text:'Paid',
          value: 'date'
        }
        // {
        //   text: 'View Invoice',
        //   value: 'actions'
        // },
      ],
      faculties: [],
      invoiceDatas: []
    }
  },

  watch: {
    'filter.facultyId'(val) {
      if(val){
        this.departmentDisabled = false;
        this.populateRightDepartments(val);
      }else{
        this.departmentDisabled = true;
      }
      // if (val && this.) {
      //     this.disableFile = false;
      // }
    },
  },
  computed:{
    filteredItemsthree: function () {
                return this.filterItemsthree(this.invoiceDatas);
            },
            csvData3() {
                return this.invoiceDatas.map(item => ({
                    ...item,
                    department:item.department,
                    faculty:item.faculty,
                    level:item.level,
                    settlementType:item.settlement =='1'?"Automatic":"Manual",
                    status:item.status=='1'?"Paid":"Not Paid"

                }));
            },
        },

  beforeMount() {
    // this.fetchInoice({
    //   page: 1
    // });
    this.fetchFees();
    this.fetchDepartments();
    this.fetchFaculties();
  },
  mounted(){
    this.program = this.$router.currentRoute.params.activeSetting.data.data.program._id;
  },

  
  methods: {

    filterItemsthree: function (arr, query) {
                return arr.filter(function (item) {
                    let dept = item.department.name.toString().toLowerCase();
                    let facul = item.faculty.name.toString().toLowerCase();
                    return (dept && facul);
                })
            },
    csvExport(arrData) {
                // if (this.filteredItemsthree.length == 0) {
                //     Swal.fire({
                //         icon: "info",
                //         text: "Table Is Empty",
                //         width: 400,
                //     });
                // } else {
                    let csvContent = "data:text/csv;charset=utf-8,";
                    csvContent += [
                            Object.keys(arrData[0]),
                            ...arrData.map(item => Object.values(item))
                        ]
                        // .trim()
                        .join("\n")
                        .replace(/(^\[)|(\]$)/gm, "");
    
                    const data = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", data);
                    link.setAttribute("download", "paymentstudentlist.csv");
                    link.click();
               // }
            },

    clear() {
      this.filter = {
        departmentId: '',
        facultyId: '',
        feeId: '',
        dateFrom:'',
        dateTo:'',
        regNumber: '',
        invoiceRef: '',
        programId: '',
      }
    },
    closeDate1(){
      this.modall = false;
    },  
    populateRightDepartments(val) {
      console.log(val)
      if (val === '') return;
      console.log(this.departmentsHolder);
      const departmentDatas = this.departmentsHolder.filter(
        (el) => el.faculty._id === val
      );
      const departments = departmentDatas.map((el) => {
        return {
          text: el.name,
          value: el._id
        }
      });
      this.departments = departments;
    },
    showinvoice(item) {
      console.log(item);
      // this.$router.push('/')
    },
    filterInvoice() {
      this.fetchInoice({
        ...this.filter,
        page: 1
      })
      console.log(this.filter)
    },
    prev() {
      this.fetchInoice({
        page: this.pageCount,
        ...this.filter
      });
      this.pageCount--;
    },

    async fetchFaculties() {
      try {
        const res = await allFaculties().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
if (res.data.code == '00') {

this.faculties = res.data.message.map((el) => {
  return {
    text: el.name,
    value:  el._id
  }
});
// console.log(datas);

}
      } catch (error) {
        console.log(error);
      }
    },

    // async fetchDepartments() {
    //   try {
    //     const res = await axios.get("http://127.0.0.1:5000/getalldepartments");
    //     this.departmentsHolder = res.data.data;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },



    async fetchDepartments() {
      try {
        this.department = '';
        
        const res = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
})
        console.log('Department', res.data.message);
          this.departmentsHolder = res.data.message;
      } catch (error) {
        console.log(error);
      }
    },


    async fetchFees() {
      try {
        this.fees = [];
        const url = this.$commons+'/get-fee';
        const res = await axios.post(url);
        if (res.data.code === '00') {
          res.data.message.forEach(fee => {
            this.fees.push({
              text: fee.name,
              value: fee._id
            })
          });

        }

      } catch (error) {
        console.log(error);
      }
    },

    next() {
      this.fetchInoice({
        // page: this.pageCount,
        ...this.filter
      });
      // this.pageCount++;
    },
    viewInvoice(item) {
      console.log(item)
    },
    async fetchInoice(payload) {
      this.activities = true
      this.invoiceDatas = []
      try {
        
        const res = await axios.post(this.$appUrl+'/fetch-invoice', {
          ...payload
        });

        console.log(res);
        this.dialogLoad = true;
        if (res.data.code == 's200') {
          const invoiceDatas = []
          res.data.message.forEach(el => {
var sessionComp = parseFloat(el.session)+parseFloat(1);
const faculty =  el.facultyId?el.facultyId.name:"N/A";
const department =  el.departmentId?el.departmentId.name:"African and asia Studies";
            invoiceDatas.push({
              regnumber: el.regNumber,
              status:el.status,
              ref: el?.invoiceRef,
              amountToPay: el.amountToPay,
              amountPaid: el.amountPaid,
              // feename: el.feeId.name,
              session: el.session+'/'+sessionComp,
              faculty: faculty,
              department: department,
              stuname: `${el?.studentId?.surname} ${el?.studentId?.firstname}`,
              // program: el.programId.program,
              date: el.datePaid.split('T')[0],
              genDate:el.dateGenerated.split('T')[0],
              feename:el.feeId.name,
              settlement:el.settlement,
              level:el?.studentId?.level
            });
          })
          this.invoiceDatas = invoiceDatas;
          this.dialogLoad = false;
        } else {
          this.dialogLoad = false;
        }
        this.activities = false
      } catch (error) {
        this.activities = false
        console.log(error);
        this.dialogLoad = false;
      }
    }
  }
}
</script>

<style>
.btn-search {
  color: #fff !important;
  padding: 1.1rem !important;
  font-size: 10px !important;

}

.btn-clear {
  padding: 1.1rem !important;
  font-size: 10px !important;
}
</style>
