import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import dashboard from "../components/Dashboard";
import invoices from "../components/invoiceList"
import {parseJwt,getCookie} from '@Appmart/utility'

//import allocatedRegnumb from '../components/'

// import {getLoggedInUser,getCookie,checkMenuEligibility,parseJwt} from '@Appmart/utility'

Vue.use(VueRouter)
const routes = [
  {
    path: '/bursary/dashboard',
    name: 'dashboard',
    component: dashboard,
   
    //  beforeEnter: async (to, from, next) => {
    //   const url = process.env.VUE_APP_BURSARY_V1_API_ENDPOINT+'get-fee-analysis'
    //   const get_paid_fee_data = process.env.VUE_APP_BURSARY_V1_API_ENDPOINT+'get-paid-fee-data'
    //   console.log(url);
    //   /**
    //    * 
    //    * note to do
    //    * Find a way to get session into this place
    //    */
    //    const FeeAnalysis =  await axios({
    //         method: 'POST',
    //         url: url,
    //         data: {
    //             session: 2021
    //         }
    //     }).catch(e =>{
    //       console.log(e);
    //       alert('Something went wrong')
    //           next(false)
    //     })

    //     const FeeData =  await axios({
    //       method: 'POST',
    //       url: get_paid_fee_data,
    //       data: {
    //           session: 2021
    //       }
    //   }).catch(e =>{
    //     console.log(e);
    //     alert('Something went wrong')
    //         next(false)
    //   })

    //     if(FeeAnalysis.data.code =='00' && FeeData.data.code =='00' ){
    //           to.params.fetchFeeAnalysis = FeeAnalysis.data;
    //           to.params.fetchFeeData = FeeData.data;
    //           next({ params: to.params });
    //       }else{
    //         alert('Something went wrong')
    //         next(false)
    //       }
    //         // axios.post(url,{session: 2021})
    //         //   .then(response => {
    //         //     console.log(response);
    //         //     if(response.data.code =='00'){
    //         //     console.log(response.data);
    //         //     to.params.fetchFeeAnalysis = response.data;
    //         //     next({ params: to.params });
    //         //     }
    //         //   })
    //         //   .catch(e => {
    //         //     alert('Something went wrong')
    //         //     next(false)
    //         //   })
    //       },
  },

  {
    path: '/bursary/PaidInvoices',
    name: 'Invoice List',
    component: invoices,
  }
]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})



 router.beforeEach((to, from, next) => {
  console.log(to);  
  if(to.matched.some(record => record.meta.requiresAuth)) {
    let name = getCookie("-X_Zka");
    console.log(name);
    if(!name){
  window.location.href =`${window.location.origin}/#/auth`;
}else{
  if(to.matched.some(record1 => record1.meta.check_link)) {
    const jwtDecode = parseJwt(name)
    // check if user is eligible for the menu
  var url = process.env.VUE_APP_SETTINGS_V1_API_ENDPOINT+"/verifyMenu";
   axios.post(url,{
    staffid:jwtDecode.staffid,
    menuPath:to.fullPath
   })
                .then(response => {
                  if(response.data.code =='00'){
                    console.log(response.data.message);
                    if(response.data.message == true){
                      next()
                    }else{
                      window.location.href =`${window.location.origin}/#/auth`;
                    }
                  }else{
                    window.location.href =`${window.location.origin}/#/auth`;
                    
                  }
                })
                .catch(e => {
                  window.location.href =`${window.location.origin}/#/auth`;
                  alert('Something went wrong')
                })  
  }else{
    next()
  }
}
  }else{
    if(to.matched.some(record => record.meta.check_link)) {

      const jwtDecode = parseJwt(name)
      // check if user is eligible for the menu
    var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
     axios.post(url,{
      staffid:jwtDecode.staffid,
      menuPath:to.fullPath
     })
                  .then(response => {
                    if(response.data.code =='00'){
                      console.log(response.data.message);
                      if(response.data.message == true){
                        next()
                      }else{
                        window.location.href =`${window.location.origin}/#/auth`;
                      }
                    }else{
                      window.location.href =`${window.location.origin}/#/auth`;
                      
                    }
                  })
                  .catch(e => {
                    window.location.href =`${window.location.origin}/#/auth`;
                    alert('Something went wrong')
                  })    }else{
    next()
    }
  }
  next()
})


export default router



function checkMenu(name){
  const jwtDecode = parseJwt(name)
  // check if user is eligible for the menu
  var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
  axios.post(url,{
  staffid:jwtDecode.staffid,
  menuPath:to.fullPath
 })
              .then(response => {
                if(response.data.code =='00'){
                  console.log(response.data.message);
                  if(response.data.message == true){
                    next()
                  }else{
                    window.location.href =`${window.location.origin}/#/auth`;
                  }
                }else{
                  window.location.href =`${window.location.origin}/#/auth`;
                  
                }
              })
              .catch(e => {
                window.location.href =`${window.location.origin}/#/auth`;
                alert('Something went wrong')
              })
}
